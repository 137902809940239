import React, { useState, FC } from 'react'

import { mixin, withMediaMobile } from '../../plugin/media-query'
import styled, { css } from 'styled-components'

import { MdIcon } from '../common'

const NavItem = mixin(
    styled.p`
        color: #fff;
        font-size: 16px;
        font-weight: 900;
        cursor: pointer;

        &:hover {
            color: #082b49;
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        transition: color 0.3s;
    `,
    withMediaMobile(
        css`
            display: none;
        `
    )
)

const NavContainer = mixin(
    styled.nav`
        background-color: #e25d6e;
        height: 60px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    `,
    withMediaMobile(
        css`
            justify-content: flex-start;
            padding-left: 20px;
        `
    )
)

const MenuIcon = mixin(
    styled(MdIcon)`
        display: none;
    `,
    withMediaMobile(
        css`
            display: block;
            font-size: 40px;
            cursor: pointer;
        `
    )
)

const Transition: FC<{ className?: string; show: boolean }> = ({
    show,
    className,
    children,
}) => {
    return (
        <div className={`${show ? 'show' : 'hide'} ${className}`}>
            {children}
        </div>
    )
}

const PopUpContainer = mixin(
    styled(Transition)`
        opacity: 0;
        transition: opacity 0.5s;
        z-index: 10;
        display: none;
    `,
    withMediaMobile(
        css`
            display: block;
            opacity: 0;
            position: fixed;
            top: 60px;
            left: 48px;
            background-color: #52bdf2;
            width: 80%;
            padding: 24px;
            color: #fff;
            font-size: 16px;
            font-weight: 900;

            &.show {
                opacity: 100;
            }

            &.hide {
                opacity: 0;
            }
        `
    )
)

const PopUpItem = mixin(
    styled.p`
        display: none;
    `,
    withMediaMobile(
        css`
            display: block;
            color: #fff;
            font-size: 16px;
            font-weight: 900;
            margin-bottom: 24px;
            cursor: pointer;

            &:hover {
                color: #082b49;
            }

            transition: color 0.3s;
        `
    )
)

export const NavBar = () => {
    const [show, setShow] = useState(false)
    const onClickShow = () => {
        setShow(!show)
        console.log(show)
    }

    return (
        <NavContainer>
            <NavItem>
                <a href="./">Home</a>
            </NavItem>
            <NavItem>
                <a href="#about">About</a>
            </NavItem>
            <NavItem>
                <a href="#service">Service</a>
            </NavItem>
            <NavItem>
                <a href="#gallery">Gallery</a>
            </NavItem>
            <NavItem>
                <a href="#contact">Contact</a>
            </NavItem>
            <MenuIcon color="#fff" onClick={() => onClickShow()}>
                menu
            </MenuIcon>
            <PopUpContainer show={show}>
                <PopUpItem>
                    <a href="./">Home</a>
                </PopUpItem>
                <PopUpItem>
                    <a href="#about">About</a>
                </PopUpItem>
                <PopUpItem>
                    <a href="#service">Service</a>
                </PopUpItem>
                <PopUpItem>
                    <a href="#gallery">Gallery</a>
                </PopUpItem>
                <PopUpItem>
                    <a href="#contact">Contact</a>
                </PopUpItem>
            </PopUpContainer>
        </NavContainer>
    )
}
