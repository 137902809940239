import React from 'react'
import './App.css'

import { NavBar, Footer } from './components/layout'

import { TitleSection } from './components/title'
import { AboutSection } from './components/about'
import { ServiceSection } from './components/service'
import { GallerySection } from './components/gallery'
import { ContactSection } from './components/contact'
import { ProfileSection } from './components/profile'

function App() {
    return (
        <>
            <NavBar />
            <TitleSection />
            <AboutSection />
            <ServiceSection />
            <GallerySection />
            <ContactSection />
            <ProfileSection />
            <Footer />
        </>
    )
}

export default App
