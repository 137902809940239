import React from 'react'

import { mixin } from '../../plugin/media-query'
import styled from 'styled-components'

const FooterContainer = mixin(
    styled.footer`
        background-color: #e25d6e;
        height: 30px;
        display: flex;
        justify-content: center;
        color: #fff;
        font-size: 12px;
        align-items: center;
    `
)

export const Footer = () => {
    const year = new Date().getFullYear()
    return (
        <FooterContainer>
            <span>&copy; ほんだ母乳育児相談室, {year} </span>
        </FooterContainer>
    )
}
