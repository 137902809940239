import React from 'react'

import { mixin, withMediaMobile } from '../../plugin/media-query'
import styled, { css } from 'styled-components'

const ContactContainer = mixin(
    styled.section`
        display: block;
        position: relative;
        background-color: #d4edf4;
    `,
    withMediaMobile(
        css`
            height: auto;
        `
    )
)

const TitleArea = mixin(
    styled.div`
        display: flex;
        justify-content: center;
        padding: 24px;

        h1 {
            font-size: 48px;
        }
    `
)

const MapArea = mixin(
    styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0 100px;
    `,
    withMediaMobile(
        css`
            padding: 0 24px;
        `
    )
)

const MapItem = mixin(
    styled.div`
        width: 100%;
        height: 400px;
        margin-bottom: 16px;

        iframe {
            width: 100%;
            height: 100%;
        }
    `,
    withMediaMobile(
        css`
            height: 200px;
        `
    )
)

const MapText = mixin(
    styled.p`
        color: #301f0d;
        display: block;
        width: 100%;
        font-size: 14px;
        text-align: right;
    `,
    withMediaMobile(
        css`
            font-size: 14px;
        `
    )
)

export const ContactSection = () => {
    return (
        <ContactContainer id="contact">
            <TitleArea>
                <h1>Contact</h1>
            </TitleArea>

            <MapArea>
                <MapItem>
                    <iframe
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1184.3594194312248!2d141.207945508211!3d38.42892338697131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f89a23e39667d27%3A0x962f0d065127cbd7!2z44CSOTgxLTA1MDQg5a6u5Z-O55yM5p2x5p2-5bO25biC5bCP5p2-6Iul6JGJ77yZ4oiS77yR!5e0!3m2!1sja!2sjp!4v1613648627861!5m2!1sja!2sjp"
                        frameBorder="0"
                        style={{ border: 0 }}
                        aria-hidden="false"
                        tabIndex={0}
                    ></iframe>
                </MapItem>
                <MapText>981-0504</MapText>
                <MapText>宮城県東松島市小松若葉９−１</MapText>
                <MapText>ほんだ母乳育児相談室</MapText>
                <MapText>0225-83-3597</MapText>
            </MapArea>
        </ContactContainer>
    )
}
