import React from 'react'

import { mixin, withMediaMobile } from '../../plugin/media-query'
import styled, { css } from 'styled-components'

const TitleContainer = mixin(
    styled.section`
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        height: 500px;
    `,
    withMediaMobile(
        css`
            height: 200px;
        `
    )
)

const BodyArea = mixin(
    styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100%;
        white-space: nowrap;
        padding-left: 24px;
        background-color: #ffb5ba;
    `,
    withMediaMobile(
        css`
            padding-left: 16px;
        `
    )
)

const ImageArea = mixin(
    styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-image: url(${`${process.env.PUBLIC_URL}/images/title-back.jpg`});
        background-size: cover;
        background-color: rgba(255, 255, 255, 0.2);
        background-blend-mode: lighten;
        width: 50%;
        height: 100%;
    `
)

const SubTitle = mixin(
    styled.p`
        color: #301f0d;
        font-size: 40px;
        font-weight: 900;
    `,
    withMediaMobile(
        css`
            font-size: 16px;
        `
    )
)

const Title = mixin(
    styled.h1`
        color: #fff;
        font-size: 64px;
        font-weight: 900;
    `,
    withMediaMobile(
        css`
            font-size: 24px;
        `
    )
)

export const TitleSection = () => {
    return (
        <TitleContainer>
            <ImageArea></ImageArea>
            <BodyArea>
                <SubTitle>笑顔の絶えない相談室！</SubTitle>
                <Title>ほんだ母乳育児相談室</Title>
            </BodyArea>
        </TitleContainer>
    )
}
