import React from 'react'

import { mixin, withMediaMobile } from '../../plugin/media-query'
import styled, { css } from 'styled-components'

const ServiceContainer = mixin(
    styled.section`
        display: block;
        position: relative;
        background-color: #d4edf4;
        padding-bottom: 40px;
    `,
    withMediaMobile(
        css`
            height: auto;
        `
    )
)

const TitleArea = mixin(
    styled.div`
        display: flex;
        justify-content: center;
        padding: 24px;

        h1 {
            font-size: 48px;
        }
    `
)

const GridContainer = mixin(
    styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        padding: 0 36px;
        color: #4a4a4a;
    `,
    withMediaMobile(
        css`
            grid-template-columns: 1fr 1fr;
        `
    )
)

const GridItem = mixin(
    styled.div`
        display: block;
        p {
            font-size: 16px;
        }
        padding-top: 12px;
        font-weight: 600;
    `,
    withMediaMobile(
        css`
            p {
                font-size: 16px;
            }
        `
    )
)

const NoWrapP = styled.p`
    white-space: nowrap;
`

const GridItemExpand = mixin(
    styled.div`
        display: block;
        p {
            font-size: 16px;
        }
        padding-top: 12px;
    `,
    withMediaMobile(
        css`
            grid-column: span 2;
            p {
                font-size: 16px;
            }
            padding-bottom: 12px;
            border-bottom: 2px solid #fff;
        `
    )
)

export const ServiceSection = () => {
    return (
        <ServiceContainer id="service">
            <TitleArea>
                <h1>Service</h1>
            </TitleArea>
            <GridContainer>
                <GridItem>
                    <p>診察料</p>
                </GridItem>
                <GridItem>
                    <p>5000円（初診）</p>
                    <p>3000円（再診）</p>
                </GridItem>
                <GridItemExpand>
                    <p>
                        乳房マッサージをしながら、トラブルの原因を解消していきます。
                    </p>
                    <p>赤ちゃんの体重チェックや授乳指導も行います。</p>
                </GridItemExpand>

                <GridItem>
                    <p>診療時間外</p>
                </GridItem>
                <GridItem>
                    <p>1000円増</p>
                </GridItem>
                <GridItemExpand>
                    <p>急患の対応にできるだけ応じます。</p>{' '}
                </GridItemExpand>

                <GridItem>
                    <p>母乳産前教室</p>
                </GridItem>
                <GridItem>
                    <p>2000円（個別）</p>
                </GridItem>
                <GridItemExpand>
                    <p>出産前の乳房チェックや産後の母乳育児の準備をします。</p>{' '}
                </GridItemExpand>

                <GridItem>
                    <p>カウンセリング</p>
                </GridItem>
                <GridItem>
                    <p>3000円（60分）</p>
                </GridItem>
                <GridItemExpand>
                    <p>
                        お子さまの育児全般、お母様の心身のお悩みをじっくりお聞きいたします。
                    </p>{' '}
                </GridItemExpand>

                <GridItem>
                    <p>育児相談（乳房マッサージを除く）</p>
                </GridItem>
                <GridItem>
                    <p>2000円（30分）</p>
                    <p>3000円（60分）</p>
                </GridItem>
                <GridItemExpand>
                    <p>
                        赤ちゃんの体重増加、離乳食の不安、ミルクの与え方など個別にゆっくり相談をお受けします。
                    </p>{' '}
                </GridItemExpand>

                <GridItem>
                    <NoWrapP>ボディケア（オイルトリートメント）</NoWrapP>
                </GridItem>
                <GridItem>
                    <p></p>
                </GridItem>
                <GridItemExpand>
                    <p>全身（頭〜足先まで）：7000円（80分）</p>{' '}
                    <p>頭〜デコルテ：2000円（30分）</p>{' '}
                    <p>膝〜足先：3000円（40分）</p>{' '}
                    <p>腕〜手：2000円（30分）</p>{' '}
                </GridItemExpand>
            </GridContainer>
        </ServiceContainer>
    )
}
