import React, { FC } from 'react'

export const MdIcon: FC<{
    color: string
    className?: string
    onClick?: () => void
}> = ({ color, children, className, onClick }) => {
    const styleObj = {
        color: color,
    }

    return (
        <i
            className={`material-icons ${className}`}
            style={styleObj}
            onClick={() => {
                if (onClick) {
                    onClick()
                }
            }}
        >
            {children}
        </i>
    )
}
