import React from 'react'

import { mixin, withMediaMobile } from '../../plugin/media-query'
import styled, { css } from 'styled-components'

const ProfileContainer = mixin(
    styled.section`
        display: block;
        position: relative;
        padding-bottom: 40px;
    `,
    withMediaMobile(
        css`
            height: auto;
        `
    )
)

const TitleArea = mixin(
    styled.div`
        display: flex;
        justify-content: center;
        padding: 24px;

        h1 {
            font-size: 48px;
        }
    `
)

const ProfileArea = mixin(
    styled.div`
        display: flex;
        justify-content: center;
        padding: 0 100px;
    `,
    withMediaMobile(
        css`
            flex-direction: column;
            align-items: center;
        `
    )
)

const OvalImage = mixin(
    styled.div`
        display: inline-block;
        width: 200px;

        img {
            border-radius: 5%;
            overflow: hidden;
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    `
)

const ProfileText = mixin(
    styled.p`
        color: #301f0d;
        width: 330px;
        font-size: 16px;
        text-align: left;
        padding-top: 40px;
        padding-left: 40px;
    `
)

const CertText = mixin(
    styled.p`
        color: #301f0d;
        width: 330px;
        font-size: 12px;
    `
)

export const ProfileSection = () => {
    return (
        <ProfileContainer>
            <TitleArea>
                <h1>Profile</h1>
            </TitleArea>
            <ProfileArea>
                <OvalImage>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/yumi-honda.jpg`}
                        alt=""
                    />
                    <CertText>
                        本田由美
                        <br />
                        助産師
                        <br />
                        看護師
                        <br />
                        <br />
                        JREC認定リフレクソロジスト
                        <br />
                        トップインストラクター
                        <br />
                        サポートケアリフレクソロジスト
                    </CertText>
                </OvalImage>
                <ProfileText>
                    助産師の本田由美です。
                    <br />
                    不安いっぱいで、相談に来たママが帰りには笑顔になってくださる時が、一番うれしいです。
                </ProfileText>
            </ProfileArea>
        </ProfileContainer>
    )
}
