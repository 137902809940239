import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { mixin, withMediaMobile } from '../../plugin/media-query'
import Slider from 'react-slick'

const GalleryContainer = mixin(
    styled.section`
        display: block;
        position: relative;
        padding-bottom: 24px;
    `,
    withMediaMobile(
        css`
            height: auto;
        `
    )
)

const TitleArea = mixin(
    styled.div`
        display: flex;
        justify-content: center;
        padding: 24px;

        h1 {
            font-size: 48px;
        }
    `
)

const GalleryArea = mixin(
    styled.div`
        display: block;
        padding: 24px;
    `
)

const ImageArea = mixin(
    styled.div`
        width: 100%;
        img {
            object-fit: contain;
            width: 100%;
            height: 400px;
        }
    `,
    withMediaMobile(
        css`
            img {
                object-fit: contain;
                width: 100%;
                height: 200px;
            }
        `
    )
)

export const GallerySection = () => {
    const [config, setConfig] = useState({
        centerMode: true,
        infinite: true,
        slidesToShow: window.innerWidth < 500 ? 1 : 2,
        autoplay: true,
        autoplaySpeed: 2000,
    })

    const f = () => {
        console.log(window.innerWidth)
        if (window.innerWidth < 580) {
            setConfig({
                centerMode: true,
                infinite: true,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 2000,
            })
        } else {
            setConfig({
                centerMode: true,
                infinite: true,
                slidesToShow: 2,
                autoplay: true,
                autoplaySpeed: 2000,
            })
        }
    }

    useEffect(() => {
        f()
        window.addEventListener('resize', f)

        return () => window.removeEventListener('resize', f)
    }, [])

    return (
        <GalleryContainer id="gallery">
            <TitleArea>
                <h1>Gallery</h1>
            </TitleArea>
            <GalleryArea>
                <Slider {...config}>
                    <ImageArea>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/slider-1.jpg`}
                            alt=""
                        />
                    </ImageArea>
                    <ImageArea>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/slider-2.jpg`}
                            alt=""
                        />
                    </ImageArea>
                    <ImageArea>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/slider-3.jpg`}
                            alt=""
                        />
                    </ImageArea>
                    <ImageArea>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/slider-4.jpg`}
                            alt=""
                        />
                    </ImageArea>
                </Slider>
            </GalleryArea>
        </GalleryContainer>
    )
}
