import React from 'react'

import { mixin, withMediaMobile } from '../../plugin/media-query'
import styled, { css } from 'styled-components'

const AboutContainer = mixin(
    styled.section`
        display: block;
        position: relative;
    `,
    withMediaMobile(
        css`
            height: auto;
        `
    )
)

const Container = mixin(
    styled.div`
        display: flex;
        flex-direction: row-reverse;
        position: relative;
    `,
    withMediaMobile(
        css`
            height: auto;
            flex-direction: column-reverse;
        `
    )
)

const TitleArea = mixin(
    styled.div`
        display: flex;
        justify-content: center;
        padding: 24px;

        h1 {
            font-size: 48px;
        }
    `
)

const BodyArea = mixin(
    styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100%;
        padding-left: 24px;
        background-color: #fff;
    `,
    withMediaMobile(
        css`
            padding-left: 16px;
            align-items: center;
            width: auto;
        `
    )
)

const ImageArea = mixin(
    styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        width: 50%;
        height: 100%;
        padding: 32px;
    `,
    withMediaMobile(
        css`
            display: flex;
            justify-content: center;
            width: 100%;
        `
    )
)

const OvalImageArea = styled.div`
    img {
        border-radius: 5%;
        overflow: hidden;
        width: 100%;
        height: auto;
        object-fit: contain;
    }
`

const Title = mixin(
    styled.h1`
        color: #301f0d;
        font-size: 32px;
        font-weight: 900;
        margin-bottom: 16px;
    `,
    withMediaMobile(
        css`
            font-size: 20px;
            margin-bottom: 16px;
        `
    )
)

const BodyText = mixin(
    styled.p`
        color: #301f0d;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 12px;
    `,
    withMediaMobile(
        css`
            font-size: 16px;
            margin-bottom: 12px;
            padding: 0 24px;
        `
    )
)

export const AboutSection = () => {
    return (
        <AboutContainer id="about">
            <TitleArea>
                <h1>About</h1>
            </TitleArea>
            <Container>
                <BodyArea>
                    <Title>赤ちゃんはママの笑顔が大好きです！</Title>
                    <BodyText>当相談室は1998年から開業しています。</BodyText>
                    <BodyText>緑と笑顔がたえない相談室です。</BodyText>
                    <BodyText>
                        乳房のマッサージをしながら、母乳トラブルに対応しています。
                        <br />
                        授乳指導、混合・人工栄養、離乳食の相談もお受けしています。
                        <br />
                        母乳育児に限らず、お子さまの育児全般、お母様の心身のお悩みも、じっくりお聞きいたします。
                    </BodyText>
                    <BodyText>
                        また、各種ボディケアも充実しておりますので、育児の疲れを癒やしてください。
                    </BodyText>
                </BodyArea>
                <ImageArea>
                    <OvalImageArea>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/about-back.jpg`}
                            alt=""
                        />
                    </OvalImageArea>
                </ImageArea>
            </Container>
        </AboutContainer>
    )
}
