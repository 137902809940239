import styled, { css, AnyStyledComponent } from 'styled-components'
import mediaQuery from 'styled-media-query'

export const mediaMobile = mediaQuery.lessThan('medium')
export const mediaTablet = mediaQuery.between('medium', 'large')
export const mediaDesktop = mediaQuery.greaterThan('large')

export const withMediaMobile = (extend: any) => {
    return css`
        ${mediaMobile`${extend}`}
    `
}

export const withMediaTablet = (extend: any) => {
    return css`
        ${mediaTablet`${extend}`}
    `
}

export const withMediaDesktop = (extend: any) => {
    return css`
        ${mediaDesktop`${extend}`}
    `
}

export const mixin = <T extends AnyStyledComponent>(
    Component: T,
    ...extend: any
) => {
    return styled(Component)`
        ${extend}
    `
}
